<template>
    <div v-if="item" class="page-content">
        <page-breadcrumb title="Offer Detail" class="mb-2" :items="breadcrumbItems" />
        <form-generator :model="item" :schema="item_form_schema" />
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteItem"
            :is-deleting="isDeleting"
        />
    </div>
</template>
<script>
import service from '../service';
const item_form_schema = [
  {
    cols: 12,
    fields: [
        { label: 'Enable Offer', field: 'is_enable', input_type: 'switch'},
        { label: 'Market', field: 'market', input_type: 'country', },
        {
            label: 'Platform', field: 'platform', input_type: 'select', options: [
                { text: 'All', value: null },
                { text: 'Ios', value: 'ios' },
                { text: 'Android', value: 'android' }
            ]
        },
        { label: 'Name', field: 'name', validate: { required: true } },
        { label: 'Offer icon URL', field: 'icon', validate: { required: true }},
        { label: 'Key', field: 'key', validate: { required: true } },
        { label: 'Sale off', field: 'sale_off' },
        { label: 'Product ID', field: 'product_id' },
        { label: 'User Permission', field: 'user_permission', input_type: 'select', options: [
            {text: "All Users", value: 1},
        ], validate: { required: true } },
        { label: 'Trigger', field: 'trigger', input_type: 'select', options: [
            {text: "Special Offer", value: "SPECIAL_OFFER"},
        ], validate: { required: true }, width: "60%", class: "mr-1"},
        { label: 'X', field: 'trigger_params', subField: 'x', input_type: "number", width: "15%", class: "mr-1", displayCond(model) { return model.trigger === "USER_REGISTED_XY" }, validate:{ type: "number"}},
        { label: 'Y', field: 'trigger_params', subField: 'y', input_type: "number", width: "15%", displayCond(model) { return model.trigger === "USER_REGISTED_XY" }, validate:{ type: "number"}},
        { label: 'Delay Time (hours)', field: 'delay_time', validate: { required: true }},
        { label: 'Start Time', field: 'start_time', input_type: 'datetime', validate: { required: true }},
        { label: 'End Time', field: 'end_time', input_type: 'datetime', validate: { required: true }},
        { label: 'Set Expire time by Duration', field: 'is_enable_duration', input_type: 'switch'},
        { label: 'Expired At', field: 'offer_exipred_at', input_type: 'datetime', displayCond(model) { return model.is_enable_duration === false }},
        { label: 'Duration (hours)', field: 'duration', displayCond(model) { return model.is_enable_duration === true }},
    ],
    style: "display: flex; flex-wrap: wrap"
  }
];
export default {
    data(){
        return {
            item: null,
            item_form_schema,
            isUpdating: false,
            isDeleting: false,
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Offer',
                    to: { name: 'offer-list' },
                },
                {
                    text: `${this.item?.name || this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created() {
        this.getDetail()
    },
    methods: {
        async getDetail(){
            this.item = await service.getDetail({id: this.itemId});
        },
        async update() {
            this.isUpdating = true;
            await service.update({
                data: JSON.stringify(this.item),
            });
            this.getDetail();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "offer-list" });
        },
    }
}
</script>